div.player .slick-dots {
    position: absolute;
    bottom: 10px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}





div.player .slick-dots li.slick-active button:before {
    opacity: .75 !important;
    color: #fff !important;
}

div.player .slick-dots li button:before {
    font-size: 14px !important;
}

div.player .slick-dots li button:before {
    opacity: 0.25 !important;
    color: #fff !important;
}

div.player .slick-prev {
    left: -66px !important;
  width: 15% !important;
  height: 100% !important;
  background-image: url(../../assets//images/carousel\ gradient\ -\ l.svg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

div.player .slick-next {
    right: -66px !important;
  width: 15% !important;
  height: 100% !important;
  background-image: url(../../assets//images/carousel\ gradient\ -\ r.svg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

}

div.player {
    padding-top: 150px;
    overflow: hidden;
}

div.player {}

div.player div.playerContainer {
    width: 72%;
    margin: 0 auto;
    
}

div.player div.thumbnailContainer{
    width: 72%;
    margin: 0 auto;
    background-color: #000;
    height: 44em;

}
div.player div.thumbnailContainer img{
    width: 100%;
    height:100%;
    object-fit: cover;
}

div.player div.playerContainer div.Vplayer{
    position: relative;
  z-index: 9;
  width: 100%;
  /* height: 502.5px; */
}

div.player div.playerContainer div.Vplayer div.closeIcon{
    display: none;
}
div.player div.playerContainer div.Vplayer.pipMode video{
    height: 100%;
    display: block; 
    object-fit: cover; 
}
div.player div.playerContainer div.Vplayer.pipMode .video-js{
    padding: 0;
    height: 100%;
}

div.player div.playerContainer div.Vplayer.pipMode{
    position: fixed;
  width: 100%;
  height: 100%;
  bottom: 150px;
  width: 22%;
  right: 0;
  z-index: 999999999;
  height: 200px;
}

div.player div.playerContainer div.Vplayer.pipMode:hover div.closeIcon{
    display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: #6f7072;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

div.player div.playerContainer div.Vplayer.pipMode:hover div.closeIcon svg{
    stroke: #fff !important;
    width: 10px !important;
    height: 10px !important;
}


div.player div.playerContainer img {
    display: block;
    width: 100%;
}

div.player div.detailSection {
    background-color: #213a64;
    padding: 20px 25px;
    border-radius: 5px;
    /* margin-top: 15px; */
    width: 72%;
    margin: 15px auto 15px;
}

div.player div.detailSection div.TitleSection {
    display: flex;
    align-items: center;
    color: #fff;
    width: 100%;
    margin-bottom: 20px;

}

div.player div.detailSection div.TitleSection h1.showTitle {
    font-size: 26px;
    margin-right: 30px;
}

div.player div.detailSection div.TitleSection h1.episodeTitle {
    font-size: 26px;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

div.player div.detailSection div.description{
    margin-bottom: 20px;
}

div.player div.detailSection div.description p{
    overflow: hidden;
    /* white-space: nowrap; */
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

div.player div.detailSection div.buttonSection {
    display: flex;
    width: 100%;
    justify-content: right;
    margin-right: auto;
}
div.player div.detailSection div.buttonSection button{
    transition: 0.3s ease-in-out;
    cursor: pointer;
}
div.player div.detailSection div.buttonSection button:hover{
    background-color: var(--hover-color) !important;
}
div.player div.detailSection div.buttonSection button.moreInfo {
    background-color: var(--button-color);
    border: 1px solid #fff;
    color: #fff;
    border-radius: 30px;
    outline: none;
    padding: 8px 15px;
    width: 15%;
    margin-right: 20px;
}

div.player div.detailSection div.buttonSection button.AddToList {
    display: flex;
    align-items: center;
    background-color: var(--button-color);
    border: 1px solid #fff;
    color: #fff;
    border-radius: 30px;
    outline: none;
    padding: 8px 15px;
    width: 15%;
    justify-content: center;
    margin-right: 20px;

}

div.player div.detailSection div.buttonSection button.AddToList span {
    margin-right: 10px;
}

div.player div.detailSection div.buttonSection button.AddToList img {
    width: 15px;
}

div.player div.detailSection div.buttonSection button.Share {
    display: flex;
    align-items: center;
    background-color: var(--button-color);
    border: 1px solid #fff;
    color: #fff;
    border-radius: 30px;
    outline: none;
    padding: 8px 15px;
    width: 15%;
    justify-content: center;

}
div.player div.detailSection div.buttonSection button.Share img {
   width: 12px;
   margin-left: 10px;

}
div.player div.detailSection div.buttonSection button.Share{
    margin-right: 0;
  position: relative;
}
div.player div.detailSection div.buttonSection button.Share div.shareContainer{
    position: absolute;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 6px;
  z-index: 99;
}
div.player div.detailSection div.buttonSection button.Share div.shareContainer.active{
  display: block;
   
}
div.player div.detailSection div.buttonSection button.Share div.shareContainer.no-active{
    display: none;
     
}
div.player div.detailSection div.buttonSection button.Share div.shareContainer div.facebook{
    border-radius: 6px;
    background-color: #3b5998;
    margin-bottom: 5px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
div.player div.detailSection div.buttonSection button.Share div.shareContainer div.twitter{
    border-radius: 6px;
    background-color: #00aced;
    margin-bottom: 5px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
div.player div.detailSection div.buttonSection button.Share div.shareContainer div.whatsapp{
    border-radius: 6px;
    background-color: #25d366;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
div.player div.detailSection div.buttonSection button.Share div.shareContainer {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    /* background-color: var(--secondary-bg-color); */
    width: 100%;
    -webkit-animation: slideDown 0.4s ease-in-out;
            animation: slideDown 0.4s ease-in-out;
  }
div.player div.episodeSection {
    margin-top: 30px;
}

div.player div.episodeSection h3.title {
    color: #fff;
    font-size: 24px;
    width: 85%;
    margin: 0 auto 20px;
}

div.player div.episodeSection div.slickSection.center {
    max-width: 85%;
    margin: 0 auto;
}
div.player div.episodeSection div.slickSection .slick-list {
    overflow: visible !important;
}
div.player div.episodeSection div.slickSection slider {}

div.player div.episodeSection div.slickSection div.shows {
    transition: all 0.5s ease 0s;
    padding: 20px 0;
    width: 98% !important;
}

div.player div.episodeSection div.slickSection.center div.shows {
    max-height: unset;
}

div.player div.episodeSection div.slickSection div.shows:hover {
    transform: scale(1.1);
    opacity: 1;
    cursor: pointer;
}

div.player div.episodeSection div.slickSection div.shows div.imageContainer {
    transition: 0.3s ease-in-out;
}


div.player div.episodeSection div.slickSection div.shows h4.title {
    color: var(--text-color) !important;
    font-size: 16px;
    margin-top: 6px;
    /* padding-left: 14px; */
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
div.player div.episodeSection div.slickSection.center div.shows h4.title{
    max-width: 19rem;
}

div.facebookContainer {
    background-color: transparent;
    width: 15%;
    border: 1px solid #1877f2;
    margin: 10px auto 0;
    float: right;
    display: flex;
    border-radius: 20px;
    animation: slideDown 0.4s ease-in-out;


    color: #1877f2;


    color: #1877f2;


    fill: #1877f2 !important;


}

div.facebookContainer button {
    background-color: transparent;
    width: 15%;
    border: 1px solid #1877f2;
    margin: 10px auto 0;
    border-radius: 20px;
    -webkit-animation: slideDown 0.4s ease-in-out;
    animation: slideDown 0.4s ease-in-out;
}


  button:last-child
  div.shareContainer {
  position: absolute;
  background-color: #fff;
  width: 10%;
  border-radius: 5px;
  padding: 6px;
  z-index: 99;
}

  div.buttonContainer
  button:last-child
  div.shareContainer.active {
  display: block;
  /* animation: slideOneByOne 0.6s ease-in-out; */

}

  div.buttonContainer
  button:last-child
  div.shareContainer.no-active {
  display: none;
}

  button:last-child
  div.shareContainer
  div.facebook {
  border-radius: 6px;
  background-color: #3b5998;
  margin-bottom: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: slideOneByOne 0.4s ease-in-out; */
}

  button:last-child
  div.shareContainer
  div.twitter {
  border-radius: 6px;
  background-color: #00aced;
  margin-bottom: 5px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: slideOneByOne 0.5s ease-in-out; */

}

  button:last-child
  div.shareContainer
  div.whatsapp {
  border-radius: 6px;
  background-color: #25d366;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  /* animation: slideOneByOne 0.6s ease-in-out; */

}

@media all and (max-width:2560px) {
    div.player div.episodeSection div.slickSection div.shows h4.title{
        max-width: 22rem;
    }
}

@media all and (max-width:2280px) {
    div.player div.thumbnailContainer{
        height: 38em;
    }
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 17rem;
    }
    div.player div.episodeSection div.slickSection div.shows h4.title{
        max-width: 19rem;
    }
}

@media all and (max-width:1980px) {
    div.player div.thumbnailContainer{
        height: 32em;
    }
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 13rem;
    }
    div.player div.episodeSection div.slickSection div.shows h4.title{
        max-width: 16rem;
    }
}

@media all and (max-width:1600px) {
    div.player div.thumbnailContainer{
        height: 28em;
    }
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 12rem;
    }
    div.player div.episodeSection div.slickSection div.shows h4.title{
        max-width: 14rem;
    }
    
}

@media all and (max-width:1400px) {
    div.player div.thumbnailContainer{
        height: 24em;
    }
   
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 12rem;
    }
    div.player div.playerContainer div.Vplayer.pipMode{
      height: 166px;
    }
    
    
    div.player div.detailSection div.TitleSection {
        width: 100%;
    }
}

@media all and (max-width:1200px) {
    div.player div.thumbnailContainer{
        height: 20em;
    }
    div.player div.playerContainer div.Vplayer.pipMode{
        height: 127px;
    }
   
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 10rem;
    }
    div.player div.episodeSection div.slickSection div.shows h4.title{
        max-width: 12rem;
    }
    div.player div.detailSection div.TitleSection {

        width: 100%;
    }
    
    div.player div.detailSection div.TitleSection h1.episodeTitle {
        font-size: 20px;
    }
    div.player div.detailSection div.TitleSection h1.showTitle {
        font-size: 24px;
    }
    div.player .slick-prev {
        left: -37px !important;
    }
    div.player .slick-next {
        right: -37px !important;
    }
    div.player div.detailSection div.buttonSection button.AddToList {
        width: 22%;
    }
    div.player div.detailSection div.buttonSection button.Share {
        width: 22%;
    }
}

@media all and (max-width:1024px){
   
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 12rem;
    }
    div.player div.episodeSection div.slickSection div.shows h4.title{
        max-width: 14rem;
    }
}

@media all and (max-width:980px) {
    div.player div.thumbnailContainer{
        height: 17em;
    }
    div.player div.playerContainer div.Vplayer.pipMode{
        height: 160px;
    }
    div.player div.episodeSection div.slickSection.center .slick-next {
        right:-61px !important;
    }
    div.player div.episodeSection div.slickSection.center .slick-prev {
        left: -61px !important;
    }
    div.player div.episodeSection div.slickSection.center div.shows div.imageContainer {
        width: 13rem;
    }
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 13rem;
    }
    div.player div.playerContainer div.Vplayer.pipMode{
        width: 33%;
    }
    div.player div.detailSection {
        flex-direction: column;
    }

    div.player div.detailSection div.TitleSection {
        flex-direction: column;
        margin-bottom: 20px;
        margin-right: auto;
    }

    div.player div.detailSection div.buttonSection {

        width: 100%;
    }

}

@media all and (max-width:825px){
    div.player div.episodeSection div.slickSection.center div.shows div.imageContainer {
        width: 13rem;
    }
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 13rem;
    }
}

@media all and (max-width:768px){
    div.player div.thumbnailContainer{
        height: 15em;
    }
    div.player div.playerContainer div.Vplayer.pipMode{
        height: 127px;
    }
    div.player .slick-next {
        background-image: url(../../assets//images/carousel\ gradient\ -\ l.svg) !important;
      }
    div.player div.episodeSection div.slickSection.center div.shows div.imageContainer {
        width: 16rem;
    }
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 16rem;
    }
    div.player div.detailSection div.buttonSection button.AddToList {
        width: 28%;
    }
    div.player div.detailSection div.buttonSection button.Share {
        width: 22%;
    }
}

div.buttonContainer div.shareContainer {
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: var(--secondary-bg-color);
    width: 15%;
    -webkit-animation: slideDown 0.4s ease-in-out;
            animation: slideDown 0.4s ease-in-out;
  }
  @media all and (max-width:640px){
    div.player div.thumbnailContainer{
        height: 12em;
    }
    div.player div.playerContainer div.Vplayer.pipMode{
        height: 150px;
        width: 43%;
    }
    div.player div.episodeSection div.slickSection.center .slick-next {
        right: -61px !important;
        width: 24% !important;
    }
    div.player div.episodeSection div.slickSection.center .slick-prev {
        left: -61px !important;
        width: 24% !important;

    }
    div.player div.episodeSection div.slickSection.center div.shows div.imageContainer {
        width: 95%;
    }
    div.player div.episodeSection div.slickSection.center div.shows h4.title{
        max-width: 100%;
    }
    div.player div.playerContainer div.Vplayer.pipMode{
        width: 49%;
    }
    div.player .slick-prev {
        left: 0 !important;
    }
    div.player .slick-next {
        right: 0 !important;
    }
    div.player div.detailSection div.TitleSection {
        width: 100%;
        margin-right: 0;
    }
    div.player div.detailSection div.buttonSection {
        flex-wrap: wrap;
        justify-content: left;
    }
    div.player div.detailSection div.buttonSection button {
        margin-right: 20px;
        width: 33% !important;
        margin-bottom: 20px;
    }
    div.player div.detailSection div.buttonSection button:last-child {
        margin-right: 0;
    }
    div.player div.detailSection div.TitleSection {
        flex-direction: column;
        text-align: center;
        justify-content: center;
    }
  }
  @media all and (max-width:480px){
    div.player div.playerContainer div.Vplayer.pipMode{
        width: 66%;
    }
    div.player div.thumbnailContainer{
        height: 9em;
    }
    div.player div.playerContainer div.Vplayer.pipMode{
        height: 120px;
    }
    div.player div.episodeSection div.slickSection.center .slick-next {
        right: -44px !important;
    }
    div.player div.episodeSection div.slickSection.center .slick-prev {
        left: -44px !important;
    }
    div.player div.detailSection div.buttonSection button {
        width: 51% !important;
    }
    div.player div.detailSection div.TitleSection h1.episodeTitle {
        max-width: 100%;
    }
  }
@keyframes slideDown {
    0%{
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0);
    }
}
