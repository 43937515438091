div.showCardContainer {
  width: 100%;
  cursor: pointer;
  z-index: 0;
  position: relative;
  transition: transform 3.3s ease-out, box-shadow 0.3s ease-out;
}
div.showCardContainer.noPointer {
  cursor: default;
}
div.showCardContainer div.showMoreOverlay {
  display: none;
}
div.showCardContainer:hover div.imageContainer {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
div.showCardContainer:hover div.metaData {
  background: url("../../assets/images/bg-header.png") !important;
}
div.showCardContainer:hover div.metaData div.extraInfo {
  opacity: 1;
  visibility: visible;
}
div.showCardContainer.cardHover {
  position: relative;
  z-index: -9 !important;
}
div.showCardContainer.cardHover:hover {
  z-index: -9;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
}
div.showCardContainer .square {
  width: 100%;
  transition: transform 500ms ease-out, border-radius 200ms ease-out;
}
div.showCardContainer .square.hide div.metaData {
  display: none;
}
@media all and (max-width: 980px) {
  div.showCardContainer .square.hide div.metaData {
    display: block;
  }
}
div.showCardContainer div.imageContainer {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
div.showCardContainer div.imageContainer img {
  width: 100%;
  display: block;
  transition: 0.2s ease-in-out;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
div.showCardContainer div.imageContainer:hover {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  width: 100% !important;
}
div.showCardContainer div.metaData {
  color: #fff;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 8px 10px;
  box-sizing: border-box;
  transition: 0.9s ease-in-out;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: transparent;
}
@media all and (max-width: 980px) {
  div.showCardContainer div.metaData {
    visibility: visible;
  }
}
div.showCardContainer div.metaData h1.title {
  font-size: 14px;
  color: var(--text-color) !important;
  margin: 4px 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  visibility: visible;
}
div.showCardContainer div.metaData div.extraInfo {
  margin-bottom: 6px;
  font-size: 11px;
  color: var(--text-color) !important;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
  visibility: hidden;
  /* transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out; */
}

div.showCardContainer div.metaData div.extraInfo span {
  font-size: 10px;
}
div.showCardContainer div.metaData div.extraInfo span.description {
  margin-inline-end: 6px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--text-color);
  display: -webkit-box;
  font-size: 12px;
  text-overflow: ellipsis;
  margin: 0px;
  overflow: hidden;
}
div.showCardContainer div.metaData div.extraInfo span.rating {
  padding: 1px 6px;
  border: 1px solid var(--border-color);
  font-size: 9px;
  margin-inline-end: 6px;
}/*# sourceMappingURL=showcard.css.map */