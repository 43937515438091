#shows {
    padding: 170px 0 50px;
    color: #fff;
    position: relative;
}
.no-active{
    display: none;
}
#shows ul {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
}
#shows ul li {
    width: 23%;
    margin-bottom: 20px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    margin-right: 20px;
    position: relative; 
}
#shows ul li:nth-child(4n) {
    margin-right: 0;
}
#shows ul li:hover {
    transform: scale(1.2);
    z-index: 9999;
}
#shows ul li div.imageContainer {
    width: 100%;
    overflow: hidden;
}
#shows ul li div.imageContainer img {
    display: block;
    width: 100%;
    border-radius: 10px;
}
#shows ul li:hover > span {
    display: none;
}
#shows ul li div.descriptionContainer {
    background: url(../../../assets/images/bg-header.png);
    width: 100%;
    padding: 8px 10px;
    box-sizing: border-box;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: absolute; 
    left: 0;
    visibility: hidden; 
    opacity: 0; 
    transition: visibility 0.3s ease, opacity 0.3s ease;
    z-index: 1; 
    top: auto;
}
#shows ul li:hover div.imageContainer img{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
#shows ul li:hover div.descriptionContainer {
    visibility: visible; 
    opacity: 1; 
}

#shows ul li div.descriptionContainer p {
    font-size: 12px;
    color: var(--text-color);
    margin: 0;
    display: -webkit-box; 
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; 
    text-overflow: ellipsis;
    margin-top: 2%;
    line-height: 1.4;
}
#shows div.bottom {
    background: url("../../../assets//images//shedule-gradient.png") repeat-x center bottom;
    background-size: contain;
    position: absolute;
    left: 0;
    bottom: 57px;
    width: 100%;
    height: 121px;
}
#shows div.bottom div.downArrowContainer {
    width: 55px;
    margin: 22px auto 0;
    cursor: pointer;
}
#shows div.bottom div.downArrowContainer img {
    display: block;
    width: 100%;
}

/* responsive-section */

@media all and (max-width:1200px){
    #shows ul li div.descriptionContainer {
        top: 70%;
    }
}
@media all and (max-width:980px){
    #shows ul li {
        width: 30%;
    }
    #shows ul li div.descriptionContainer {
        top: 67%;
    }
    #shows ul li:nth-child(4n) {
        margin-right: 20px;
    }
    #shows ul li:nth-child(3n) {
        margin-right: 0;
    }
}
@media all and (max-width:768px){
    #shows ul li {
        width: 46%;
    }
    #shows ul li div.descriptionContainer {
        padding-bottom: 8%;
        top: 83%;
    }
    #shows ul li:hover {
        transform: scale(1.1);
    }
    #shows ul li:nth-child(3n) {
        margin-right: 20px;
    }
    #shows ul li:nth-child(2n) {
        margin-right: 0;
    }
}
@media all and (max-width:640px){
    #shows ul li {
        width: 100%;
        margin-right: 0;
    }
    #shows ul li:hover {
        transform: scale(1.1);
    }
    #shows ul li:nth-child(3n) {
        margin-right: 0;
    }
    #shows ul li div.descriptionContainer {
        top: 90%;
    }
    #shows ul li span {
        margin-left: 4%;
    }
}
@media all and (max-width:480px){
    #shows ul li div.descriptionContainer {
        top: 85%;
    }
}
@media all and (max-width:360px){
    
}
@media all and (max-width:320px){
    
}
