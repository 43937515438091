a {
  display: inline-block;
}

button {
  outline: none;
  border: none;
}

#main .slick-dots {
  position: absolute;
  bottom: 72px !important;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

#main .slick-dots li.slick-active button:before {
  opacity: 0.75 !important;
  color: #fff !important;
}

#main .slick-dots li button:before {
  font-size: 14px !important;
}

#main .slick-dots li button:before {
  opacity: 0.25 !important;
  color: #fff !important;
}

#main {
  background-color: #010915;
  overflow-x: hidden;
}

#main header {
}

#main div.spotlight {
  padding-top: 150px;
}

#main div.spotlight div.bannerSection {
  padding-bottom: 60px;
}

#main div.spotlight div.bannerSection div.imageContainer {
  width: 70%;
  margin: 0 auto;
  position: relative;
}

#main div.spotlight div.bannerSection div.imageContainer a {
  display: block;
  width: 100%;
}

#main div.spotlight div.bannerSection div.imageContainer img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#main div.spotlight div.liveSection {
  padding-bottom: 40px;
}

#main div.spotlight div.liveSection h1 {
  color: var(--white-color);
}

#main div.spotlight div.liveSection div.containers {
  display: flex;
  justify-content: space-between;
}

#main div.spotlight div.liveSection div.containers div.leftContainer {
  width: 70%;
}
#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.thumbnailContainer {
  width: 100%;
}
#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.thumbnailContainer
  img {
  width: 100%;
  display: block;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer {
  width: 100%;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer
  img {
  width: 100%;
  display: block;
}
#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer
  div.Vplayer {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
}
#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer
  div.Vplayer
  div.closeIcon {
  display: none;
}
#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer
  div.Vplayer.pipMode:hover div.closeIcon{
    display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 9;
  background-color: #6f7072;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  }
  #main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer
  div.Vplayer.pipMode:hover div.closeIcon svg{
    stroke: #fff !important;
    width: 10px !important;
    height: 10px !important;
  }
#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer
  div.Vplayer.pipMode {
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 0;
  width: 22%;
  right: 0;
  z-index: 999999999;
  height: 200px;
}
#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer
  div.Vplayer
  video {
  width: 100%;
  height: 100%;
}
#main
  div.spotlight
  div.liveSection
  div.containers
  div.leftContainer
  div.imageContainer
  div.Vplayer {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
}

#main div.spotlight div.liveSection div.containers div.rightContainer {
  width: 30%;
  background-color: #001332;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents {
  padding: 15px;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.top {
  margin-bottom: 20px;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.top
  button.nowPlaying {
  background-color: #3b66af;
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.top
  h1 {
  font-size: 22px;
  margin-bottom: 10px;
  color: var(--white-color);
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.top
  span {
  color: #b6becb;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.top
  p {
  color: #b6becb;
  font-size: 15px;
  letter-spacing: 0.2px;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.bottom {
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.bottom
  button.upNext {
  background-color: #213a64;
  outline: none;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  padding: 10px 30px;
  width: 100%;
  border-radius: 5px;
  margin-bottom: 15px;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.bottom
  h1 {
  font-size: 22px;
  margin-bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.bottom
  span {
  color: #b6becb;
  font-size: 14px;
  margin-bottom: 10px;
  display: inline-block;
}

#main
  div.spotlight
  div.liveSection
  div.containers
  div.rightContainer
  div.contents
  div.bottom
  p {
  color: #b6becb;
  font-size: 15px;
  letter-spacing: 0.2px;
  line-height: 1.5em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#main div.spotlight div.liveSection div.buttonContainerBottom {
  margin-top: 30px;
  text-align: center;
}

#main div.spotlight div.liveSection div.buttonContainerBottom button {
  background-color: var(--button-color);
  padding: 8px 30px;
  border: 2px solid #fff;
  border-radius: 30px;
  width: 260px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

#main div.spotlight div.liveSection div.buttonContainerBottom button:hover {
  background-color: #3b66af;
}

#main div.spotlight div.liveSection div.buttonContainerBottom button img {
  width: 12px;
  margin-left: 20px;
}

#main div.buttonContainerBottom {
  margin-top: 30px;
  text-align: center;
}

#main div.buttonContainerBottom button {
  background-color: var(--button-color);
  padding: 8px 30px;
  border: 2px solid #fff;
  border-radius: 30px;
  width: 260px;
  margin: 0 auto;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

#main div.buttonContainerBottom button:hover {
  background-color: #3b66af;
}

#main div.buttonContainerBottom button img {
  width: 12px;
  margin-left: 20px;
}

#main div.whatsNewSection {
}

#main div.whatsNewSection .slick-list {
  overflow: visible !important;
}

#main div.whatsNewSection .slick-prev {
  left: -66px !important;
  width: 11% !important;
  height: 100% !important;
  background-image: url(../../assets//images/carousel\ gradient\ -\ l.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
}

#main div.whatsNewSection .slick-next {
  right: -66px !important;
  width: 11% !important;
  height: 100% !important;
  background-image: url(../../assets//images/carousel\ gradient\ -\ r.svg) !important;
  background-repeat: no-repeat;
  background-position: center;
}

/* #main div.whatsNewSection .slick-track{
  left:-94px !important
} */
#main div.whatsNewSection h1.heading {
  color: #fff;
  margin-bottom: 25px;
  width: 85%;
  margin: 0 auto;
}

#main div.whatsNewSection h3.subHeading {
  font-size: 23px;
  color: #fff;
  width: 85%;
  margin: 0 auto;
}

#main div.whatsNewSection div.slickSection {
  max-width: 98%;
  margin: 0 auto;
}

#main div.whatsNewSection div.slickSection.center {
  max-width: 85%;
  margin: 0 auto;
}

#main div.whatsNewSection div.slickSection ul {
  display: flex;
  justify-content: space-between;
  /* overflow: scroll hidden; */
  padding: 20px;
}

#main div.whatsNewSection div.slickSection ul::-webkit-scrollbar {
  display: none;
}

#main div.whatsNewSection div.slickSection div.episodes {
  max-height: 250px;
  padding: 20px 0 10px;
  width: 97% !important;
}

#main div.whatsNewSection div.slickSection div.episodes:hover {
  transform: scale(1.1);
  opacity: 1;
  cursor: pointer;
}
#main div.whatsNewSection div.slickSection div.episodes div.episodeContainer {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
}
#main div.whatsNewSection div.slickSection div.episodes div.episodeContainer div.imageContainer {
  width: 98%;
  transition: 0.3s ease-in-out;
}

#main
  div.whatsNewSection
  div.slickSection.center
  div.episodes
  div.imageContainer {
  width: 19rem;
  transition: 0.3s ease-in-out;
}

#main div.whatsNewSection div.slickSection.center div.episodes p {
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 19rem;
}
#main div.whatsNewSection div.slickSection div.episodes:hover div.episodeContainer div.descriptionContainer {
  visibility: visible; 
  opacity: 1; 
}
#main div.whatsNewSection div.slickSection div.episodes div.episodeContainer div.descriptionContainer span {
  font-size: 15px;
  color: var(--text-color);
  margin: 0;
  display: -webkit-box; 
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; 
  text-overflow: ellipsis;
  margin-bottom: 2px;
}
#main div.whatsNewSection div.slickSection div.episodes div.descriptionContainer p.showname {
  color: #fff;
    font-size: 16px;
    margin-top: 10px;
}

#main div.features {
  margin-top: 30px;
}

#main div.features .items {
  position: relative;
  padding: 200px 50px;
  overflow: hidden;
}

#main div.features div.items div.background {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  filter: blur(4px);
  overflow: hidden;
}

#main div.features div.items div.background img {
  display: block;
  width: 100%;
}

#main div.features div.items div.containers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#main div.features div.items div.containers div.leftContainer {
  width: 45%;
}

#main
  div.features
  div.items
  div.containers
  div.leftContainer
  div.imageContainer {
  display: block;
  max-width: 100%;
  height: auto;
}

#main
  div.features
  div.items
  div.containers
  div.leftContainer
  div.imageContainer
  img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 10px;
}

#main div.features div.items div.containers div.rightContainer {
  width: 50%;
}

#main div.features div.items div.containers div.rightContainer h1.title {
  color: #fbfbfb;
  font-size: 28px;
  margin-bottom: 18px;
}

#main div.features div.items div.containers div.rightContainer p {
  color: #fbfbfb !important;
}

#main
  div.features
  div.items
  div.containers
  div.rightContainer
  div.buttonContainer {
  margin-top: 30px;
  display: flex;
}

#main
  div.features
  div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button {
  margin-right: 30px;
  background-color: var(--button-color);
  padding: 8px 26px 8px 0;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #e3e3e3;
  font-size: 16px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 10rem;
  justify-content: right;
}

#main
  div.features
  div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button.removeList {
  width: 38%;
}

#main
  div.features
  div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button
  div.list {
  display: flex;
  align-items: center;
}

#main
  div.features
  div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button
  img {
  margin-left: 10px;
  width: 12px;
}

#main
  div.features
  div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button:first-child
  img {
  margin-left: 30px;
  width: 12px;
}

#main
  div.features
  div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button:last-child {
  margin-right: 0;
  position: relative;
}

#main
  div.features
  div.items
  div.containers
  div.rightContainer
  div.buttonContainer
  button:hover {
  background-color: #3b66af;
}

/* #main div.features .items {
  
    background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    background-size:  cover;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding: 150px 50px;
}
#main div.features .items div.leftContainer {
    width: 48%;
}
#main div.features .items div.leftContainer div.imageContainer {
    width: 95%;
}
#main div.features .items div.leftContainer div.imageContainer img {
    width: 100%;
    display: block;
    z-index: 9;
    border-radius: 30px;
}
#main div.features .items div.rightContainer {
    width: 52%;
}
#main div.features .items div.rightContainer h1.heading {
    color: #fff;
    font-size: 25px;
    margin-bottom: 20px;
}
#main div.features .items div.rightContainer p {
    line-height: 1.5em;
    color: #fff;
}
#main div.features .items div.rightContainer div.buttonContainer {
    margin-top: 30px;
    display: flex;
}
#main div.features .items div.rightContainer div.buttonContainer a{
  margin-right: 30px;
}
#main div.features .items div.rightContainer div.buttonContainer button {
   
    background-color: #000;
  padding: 8px 50px;
  border: 2px solid #fff;
  border-radius: 30px;
  color: #fff;
  font-size: 18px;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
}
#main div.features .items div.rightContainer div.buttonContainer button:hover{
    background-color: #213A64;
}
#main div.features .items div.rightContainer div.buttonContainer button:last-child {
    margin-right: 0;
    padding: 8px 25px;
}
#main div.features .items div.rightContainer div.buttonContainer button img {
    margin-left: 20px;
    width: 12px;
} */


@media all and (max-width: 2280px) {
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 16rem;
    transition: 0.3s ease-in-out;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 16rem;
  }
}

@media all and (max-width: 1980px) {
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 13rem;
    transition: 0.3s ease-in-out;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 13rem;
  }
}

@media all and (max-width: 1600px) {
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 12rem;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 12rem;
  }
}

@media all and (max-width: 1400px) {
  #main div.features div.items div.containers div.rightContainer h1.title {
    margin-bottom: 10px;
  }
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 12rem;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 12rem;
  }

  #main
    div.features
    div.items
    div.containers
    div.rightContainer
    div.buttonContainer {
    margin-top: 16px;
  }

  #onDemand .slick-dots {
    bottom: 105px !important;
  }
}

@media all and (max-width: 1500px) {
  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.bottom
    h1 {
    font-size: 19px;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.top
    h1 {
    font-size: 19px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media all and (max-width: 1400px) {
  #main .slick-dots {
    bottom: 109px !important;
  }
}

@media all and (max-width: 1200px) {
  /* #main div.spotlight div.liveSection div.containers {
        flex-direction: column;
    } */
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 12rem;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 12rem;
  }
  #main div.whatsNewSection div.slickSection.center {
    max-width: 98%;
    margin: 0 auto;
  }
  #main div.whatsNewSection .slick-prev {
    left: -39px !important;
  }

  #main div.whatsNewSection .slick-next {
    right: -39px !important;
  }
  #main div.spotlight div.bannerSection div.imageContainer {
    width: 80%;
  }
  #main div.spotlight div.liveSection div.containers div.leftContainer {
    width: 77%;
  }

  /* #main div.spotlight div.liveSection div.containers div.rightContainer div.contents {
        display: flex;
        justify-content: space-between;
    } */
  #main div.spotlight div.liveSection div.containers div.rightContainer {
    width: 41%;
    height: 349px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer::-webkit-scrollbar {
    display: none;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.top
    button.nowPlaying {
    padding: 8px 30px;
    font-size: 15px;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.top
    h1 {
    font-size: 15px;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.bottom
    button.upNext {
    padding: 8px 30px;
    font-size: 15px;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.bottom
    h1 {
    font-size: 15px;
  }

  /* #main div.spotlight div.liveSection div.containers div.rightContainer div.contents div.bottom {
        width: 49%;
    }
    #main div.spotlight div.liveSection div.containers div.rightContainer div.contents div.top {
        width: 49%;
    } */
  footer {
    flex-direction: column;
  }

  footer div.rightSection {
    text-align: center;
  }

  footer div.rightSection h2 {
    text-align: center;
  }

  footer div.rightSection div.storeContainer {
    justify-content: center;
  }

  #main div.spotlight div.liveSection div.containers {
    background-color: #001332;
  }

  #main div.features div.items {
    padding: 136px 50px;
  }

  #main div.features div.items div.containers div.leftContainer {
    width: 43%;
  }

  #main div.features div.items div.containers div.rightContainer {
    width: 54%;
  }

  #main div.features div.items div.containers div.rightContainer h1.title {
    font-size: 26px;
    margin-bottom: -1px;
  }

  #main
    div.features
    div.items
    div.containers
    div.rightContainer
    div.buttonContainer {
    margin-top: 11px;
  }

  #main div.features div.items div.containers div.rightContainer p {
    color: #fbfbfb;
    font-size: 13px;
  }

  #main
    div.features
    div.items
    div.containers
    div.rightContainer
    div.buttonContainer
    button {
    padding: 6px 26px 6px 0;
  }

  #main .slick-dots {
    bottom: 57px !important;
  }
}

@media all and (max-width: 1125px) {
  #main div.spotlight div.liveSection div.containers div.rightContainer {
    height: 306px;
  }
}

@media all and (max-width: 1024px) {
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 14rem;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 14rem;
  }
  #main div.whatsNewSection div.slickSection div.episodes div.imageContainer {
    transition: 0.3s ease-in-out;
  }
}

@media all and (max-width: 980px) {
  #main
    div.spotlight
    div.liveSection
    div.containers
    div.leftContainer
    div.imageContainer
    div.Vplayer.pipMode {
    width: 33%;
  }
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 16rem;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 16rem;
  }
  #main div.spotlight div.liveSection div.containers {
    background: none;
    flex-direction: column;
  }

  #main div.spotlight div.liveSection div.containers div.rightContainer {
    width: 100%;
  }

  #main div.spotlight div.liveSection div.containers div.leftContainer {
    width: 100%;
  }

  #main div.spotlight div.liveSection div.containers div.rightContainer {
    height: unset;
    overflow: visible;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents {
    display: flex;
    justify-content: space-between;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.top {
    width: 49%;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.bottom {
    width: 49%;
  }

  /* #main div.features .items div.rightContainer div.buttonContainer {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
}
#main div.features .items div.rightContainer div.buttonContainer button {
  margin-right: 0;
  width: 180px;
  margin-bottom: 10px;
}
#main div.features .items div.rightContainer div.buttonContainer button:last-child {
  margin-bottom: 0;
}
#main div.features .items div.rightContainer {
  width: 50%;
}
#main div.features .items div.rightContainer {
  width: 50%;
} */
  #main div.features div.items div.containers {
    flex-direction: column;
  }

  #main
    div.features
    .items
    div.rightContainer
    div.buttonContainer
    button.removeList {
    width: 70%;
  }

  #main div.features div.items div.background img {
    height: 100%;
    object-fit: cover;
  }

  #main div.features div.items div.containers div.leftContainer {
    width: 100%;
    margin-bottom: 20px;
  }

  #main div.features div.items div.containers div.rightContainer {
    width: 100%;
  }

  #main
    div.features
    div.items
    div.containers
    div.rightContainer
    div.buttonContainer {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
  }

  #main div.features div.items div.containers div.rightContainer p {
    font-size: 14px;
  }
}

@media all and (max-width: 825px) {
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 14rem;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 14rem;
  }
}
@media all and (max-width: 768px) {
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 18rem;
  }
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 18rem;
  }
  #main div.spotlight div.bannerSection div.imageContainer {
    margin: 0 auto;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.top
    h1 {
    font-size: 25px;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.bottom
    h1 {
    font-size: 25px;
  }

  #main div.whatsNewSection div.slickSection div.episodes div.imageContainer {
    width: 98%;
    transition: 0.3s ease-in-out;
  }
  #main div.whatsNewSection div.slickSection div.episodes div.descriptionContainer p.synopsis{
    visibility: hidden;
  }
  #main div.whatsNewSection div.slickSection div.episodes:hover div.descriptionContainer {
    visibility: visible;
  }
  #main div.whatsNewSection .slick-prev {
    left: -13px !important;
  }

  #main div.whatsNewSection .slick-next {
    right: -13px !important;
  }
  #main div.whatsNewSection div.slickSection div.episodes:hover div.descriptionContainer {
    top: 76%;
    left: 3px;
  }
  /* #main div.features .items {
    flex-direction: column;
    padding: 45px 50px 165px;
  }
  #main div.features .items div.leftContainer {
    width: 100%;
    margin-bottom: 23px;
}
#main div.features .items div.rightContainer {
  width: 100%;
}
#main div.features .items div.rightContainer div.buttonContainer {
  flex-direction: row;
}
#main div.features .items div.rightContainer div.buttonContainer button {
  margin-right: 10px;
  margin-bottom: 0;
}
#main div.features .items div.rightContainer div.buttonContainer button:last-child {
  margin-right: 0;
}
#main .slick-dots {
  bottom: 16px !important;
} */
}

@media all and (max-width: 640px) {
    #main
    div.spotlight
    div.liveSection
    div.containers
    div.leftContainer
    div.imageContainer
    div.Vplayer.pipMode {
    width: 49%;
  }
  #main
    div.whatsNewSection
    div.slickSection.center
    div.episodes
    div.imageContainer {
    width: 100%;
  }
  #main div.whatsNewSection div.slickSection div.episodes:hover {
    display: contents !important;
  }
 
  #main div.whatsNewSection div.slickSection.center div.episodes p {
    width: 100%;
  }
  #main div.whatsNewSection .slick-prev {
    background-size: cover !important;
    width: 25% !important;
  }

  #main div.whatsNewSection .slick-next {
    background-size: cover !important;
    width: 25% !important;
  }

  #main div.whatsNewSection .slick-track {
    left: 0 !important;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents {
    flex-direction: column;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.top {
    width: 100%;
  }

  #main
    div.spotlight
    div.liveSection
    div.containers
    div.rightContainer
    div.contents
    div.bottom {
    width: 100%;
  }

  #main div.whatsNewSection div.slickSection ul li div.imageContainer {
    width: 237px;
  }
  #main div.whatsNewSection h1.heading {
    font-size: 21px;
  }

  /* #main div.features .items div.rightContainer div.buttonContainer button {
      padding: 9px 27px 9px 62px;
      font-size: 19px;
      width: 280px;
      justify-content: space-between;
  }
    #main div.features .items div.rightContainer div.buttonContainer button img {
        margin-left: 10px;
    }
    #main div.features .items {
        padding: 98px 29px;
    } */
  #main div.whatsNewSection div.slickSection div.episodes div.imageContainer {
    width: 90%;
    transition: 0.3s ease-in-out;
    margin: 0 auto;
  }

  #main .slick-prev {
    left: -14px !important;
  }

  #main .slick-next {
    right: -10px !important;
  }

  #main div.features div.items {
    padding: 72px 27px 144px;
  }
}

@media all and (max-width: 480px) {
  #main div.spotlight div.bannerSection div.imageContainer {
    width: 410px;
  }

  #main div.spotlight div.liveSection h1 {
    margin-bottom: 5px;
  }

  #main div.whatsNewSection .slick-prev {
    width: 14% !important;
  }

  #main div.whatsNewSection .slick-next {
    width: 14% !important;
  }

  /* #main div.features .items div.rightContainer div.buttonContainer {
        flex-direction: column;
    }
    #main div.features .items div.rightContainer div.buttonContainer button {
        margin-right: 0;
        width: 166px;
        padding: 9px 27px 9px 56px;
        justify-content: space-between;
    }
    #main div.features .items div.rightContainer div.buttonContainer button:last-child {
        margin-top: 10px;
    } */
  #main
    div.features
    div.items
    div.containers
    div.rightContainer
    div.buttonContainer {
    flex-direction: column;
  }

  #main
    div.features
    div.items
    div.containers
    div.rightContainer
    div.buttonContainer
    button:first-child {
    margin-bottom: 15px;
  }
}

@media all and (max-width: 360px) {
  #main div.spotlight div.bannerSection div.imageContainer {
    width: 310px;
  }

  footer div.rightSection div.copyrightContainer p {
    font-size: 13px;
  }
}

@media all and (max-width: 321px) {
}
